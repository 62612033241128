export const themes = [
  {
    theme: {
      light: {
        primary: "#e2e2e2",
        secondary: "#C03725",
        text: "#050038",
        textShadow: "rgba(0, 0, 0, 0.5)",
        identity: "#332c7c",
      },
      dark: {
        primary: "#131313",
        secondary: "#C03725",
        text: "#e2e2e2",
        textShadow: "rgba(226, 226, 226, 0.5)",
        identity: "#332c7c",
      },
    },
  },
  {
    theme: {
      light: {
        primary: "#F4ECD3",
        secondary: "#C03725",
        text: "#135E3C",
        textShadow: "rgba(0, 0, 0, 0.5)",
        identity: "#135E3C",
      },
      dark: {
        primary: "#052215",
        secondary: "#C03725",
        text: "#e2e2e2",
        textShadow: "rgba(226, 226, 226, 0.5)",
        identity: "#135E3C",
      },
    },
  },
  {
    theme: {
      light: {
        primary: "#F69700",
        secondary: "#EA0902",
        text: "#0A0000",
        textShadow: "rgba(0, 0, 0, 0.5)",
        identity: "#EA0902",
      },
      dark: {
        primary: "#131313",
        secondary: "#F69700",
        text: "#EA0902",
        textShadow: "rgba(226, 226, 226, 0.5)",
        identity: "#EA0902",
      },
    },
  },
];
// export const themes = {
//   theme1: {
//     light: {
//       primary: "#e2e2e2",
//       secondary: "#79b2d8",
//       text: "#050038",
//       textShadow: "rgba(0, 0, 0, 0.5)",
//       identity: "#050038",
//     },
//     dark: {
//       primary: "#131313",
//       secondary: "#79b2d8",
//       text: "#e2e2e2",
//       textShadow: "rgba(226, 226, 226, 0.5)",
//       identity: "#050038",
//     },
//   },
//   theme2: {
//     light: {
//       primary: "#F4ECD3",
//       secondary: "#C03725",
//       text: "#135E3C",
//       textShadow: "rgba(0, 0, 0, 0.5)",
//       identity: "#135E3C",
//     },
//     dark: {
//       primary: "#131313",
//       secondary: "#79b2d8",
//       text: "#e2e2e2",
//       textShadow: "rgba(226, 226, 226, 0.5)",
//       identity: "#135E3C",
//     },
//   },
// };
export const theme1 = {
  light: {
    primary: "#e2e2e2",
    secondary: "#79b2d8",
    text: "#050038",
    textShadow: "rgba(0, 0, 0, 0.5)",
    identity: "#050038",
  },
  dark: {
    primary: "#131313",
    secondary: "#79b2d8",
    text: "#e2e2e2",
    textShadow: "rgba(226, 226, 226, 0.5)",
    identity: "#050038",
  },
};
export const theme2 = {
  light: {
    primary: "#F4ECD3",
    secondary: "#C03725",
    text: "#135E3C",
    textShadow: "rgba(0, 0, 0, 0.5)",
    identity: "#135E3C",
  },
  dark: {
    primary: "#131313",
    secondary: "#79b2d8",
    text: "#e2e2e2",
    textShadow: "rgba(226, 226, 226, 0.5)",
    identity: "#135E3C",
  },
};
// export const light = {
//   primary: "#e2e2e2",
//   secondary: "#79b2d8",
//   text: "#050038",
//   textShadow: "rgba(0, 0, 0, 0.5)",
// };
// export const dark = {
//   primary: "#131313",
//   secondary: "#79b2d8",
//   text: "#e2e2e2",
//   textShadow: "rgba(226, 226, 226, 0.5)",
// };
// export
export const initialLoadTransition = {
  duration: "0.5",
  delay: "1",
};
